// extracted by mini-css-extract-plugin
export var title = "quiz-module--title--a7CPm";
export var subtitle = "quiz-module--subtitle--3llAL";
export var container = "quiz-module--container--3sMYa";
export var stepText = "quiz-module--stepText--1hJxa";
export var introContainer = "quiz-module--introContainer--23y01";
export var ctaContainer = "quiz-module--ctaContainer--2UXOF";
export var questionContainer = "quiz-module--questionContainer--3WR2g";
export var noPadding = "quiz-module--noPadding--78nqR";
export var questionCardList = "quiz-module--questionCardList--29K1B";
export var questionCard = "quiz-module--questionCard--30NOg";
export var cardTitle = "quiz-module--cardTitle--3QYRN";
export var cardText = "quiz-module--cardText--3gZsB";
export var cardCtaContainer = "quiz-module--cardCtaContainer--32Jl2";
export var otherSolutionWrapper = "quiz-module--otherSolutionWrapper--7OL5R";
export var otherSBtn = "quiz-module--otherSBtn--egBSB";
export var otherSHoverBtn = "quiz-module--otherSHoverBtn--2FeeS";
export var otherSWHover = "quiz-module--otherSWHover--2PAIC";
export var hideOnInitial = "quiz-module--hideOnInitial--10ZBW";
export var hideOnSucsess = "quiz-module--hideOnSucsess--ayJgO";
export var showOnSucsess = "quiz-module--showOnSucsess--2R6_4";