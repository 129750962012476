import cx from "classnames";
import React, { FC } from "react";
import { NavCta } from "../../../components/Cta";

import SEO from "../../../components/seo";
import * as global from "../../../styles/global.module.scss";
import * as quizStyle from "../../../styles/shared/quiz.module.scss";

const { routes } = require("../../../routes");
interface Props {
  data: {
    [key: string]: any;
  };
  location: Location;
}

const DiscoverStep1: FC<Props> = (props: Props) => {
  const { location } = props;

  return (
    <>
      <SEO
        meta={[
          {
            name: "og:url",
            content: location.href,
          },
        ]}
        title="Constant - assistant shopping 100% éco-responsable"
      />
      <div className={cx(global.pageWrapper)}>
        <div className={quizStyle.introContainer}>
          <h2 className={cx(quizStyle.title, global.bigTitle, global.title)}>
            Vous n'êtes pas sûr <br /> de ce que vous cherchez&nbsp;?
          </h2>
        </div>
        <div className={quizStyle.questionContainer}>
          <h3 className={cx(quizStyle.title, global.title)}>
            Sélectionnez la situation qui vous correspond le plus <br /> pour
            trouver la solution qu'il vous faut.
          </h3>
          <ul className={quizStyle.questionCardList}>
            <li>
              <div className={quizStyle.questionCard}>
                <h4 className={quizStyle.cardTitle}>
                  Vous découvrez l'univers de la mode éthique.
                </h4>
                <p className={cx(quizStyle.cardText, global.text)}>
                  "Je souhaite découvrir des marques éco-resp qui correspondent
                  à mon style, sans forcément vouloir acheter"
                </p>
                <div className={quizStyle.cardCtaContainer}>
                  <NavCta
                    to={routes.app.parcours.decouverte.step2a.path}
                    label="Cliquez-ici"
                  />
                </div>
              </div>
            </li>
            <li>
              <div className={quizStyle.questionCard}>
                <h4 className={quizStyle.cardTitle}>
                  Vous avez une vague idée de ce que vous cherchez.
                </h4>
                <p className={cx(quizStyle.cardText, global.text)}>
                  "Je sais à-peu-près ce que je souhaite, mais je ne suis pas
                  certain.e de vouloir un guide personalisé"
                </p>
                <div className={quizStyle.cardCtaContainer}>
                  <NavCta
                    to={routes.app.parcours.decouverte.step2b.path}
                    label="Cliquez-ici"
                  />
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default DiscoverStep1;
